
import AddEditCategory from '@/components/agency/course_category/AddEditCategory.vue'
import { defineComponent, onMounted, ref } from 'vue'
import { ArrowLeftOutlined, HomeOutlined } from '@ant-design/icons-vue'
// import category from '../../../services/category'
import { notification } from 'ant-design-vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  components: {
    ArrowLeftOutlined,
    HomeOutlined,
    AddEditCategory
  },
  setup () {
    const route = useRoute()
    const subCatFlag = ref<boolean>(false)
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({
        message,
        description
      })
    }
    onMounted(() => {
      if (route.params.sub) {
        subCatFlag.value = true
      }
    })
    return {
      notify,
      subCatFlag
    }
  }
})
